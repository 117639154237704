@import '../../styles/scss/_variables.scss';

.layout {
    min-height: 100vh;
    padding: 0;
    // display: flex;
    // flex-flow: column;
    // flex: 1;

    // &.home {
    //     background-color: $gray-100;
    // }

    .main {
        position: relative;
        z-index: 1;
        padding: 0;
        background-color: #fff;
        // flex: 1;

        hr.grey::after {
            box-shadow: 0 0 0 5px #f8f9fa;
        }
    }
}
