.footer {
    padding: 2rem;
    position: sticky;
    bottom: 0;
    top: 100%;

    > p {
        margin: 0;
    }
}
