@import '../../styles/scss/variables';

.header {
    padding: 1rem;
    position: sticky;
    top: 0;
    z-index: 0;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    flex: 1;

    &.home {
        padding: 1rem 0;

        h1 {
            font-size: 2.2rem;
            font-family: 'Roboto', sans-serif;
            font-weight: 900;
            font-style: normal;
            margin-top: 1rem;
            text-transform: uppercase;
        }
    }
}
