@import '../../styles/scss/variables';

.about {
    background-color: #fff;
    h2 {
        color: $primary;
    }

    p {
        color: #718096;
    }
}
