@import '../../styles/scss/_variables.scss';

.navbar {
    padding: 1rem;
    justify-content: center;
    // background-color: $gray-100;
    border-bottom: 0.1rem solid $gray-200;
    position: sticky;
    top: 0;
    display: flex;

    .links {
        justify-content: center;

        > ul {
            list-style: none;
            padding: 0;
            margin: 0;
            display: flex;

            > li {
                padding: 0 1rem;

                > a {
                    // font-size: 1.4rem;
                    color: $gray-800;
                    // font-weight: 300;

                    &.active {
                        font-weight: 500;
                        color: $info;
                    }

                    &:hover {
                        text-decoration: none;
                        color: $info;
                    }
                }
            }
        }
    }
}
